import Vue from "vue";
// import MainComponents from "@nordiskemedier/main-components";
import { CvrRestApi } from "@nordiskemedier/main-components";

Vue.mixin({
    computed: {
        console: function () {
            return console
        },
        document: function () {
            return document
        },
        window: function () {
            return window
        }
    }
})

//Vue.use(MainComponents.CvrRestApi, { // needs an option
Vue.use(CvrRestApi, { // needs an option
    cvrRestApiAddress: window.cvrRestApiAddress
})

const Components = {


};

Object.keys(Components).forEach(name => {
    Vue.component(name, Components[name]);
});

export default Components;